import FooterComponent from "../FooterComponent/footerComponent";
import NavbarComponent from "../NavbarComponent/navbarComponent";
import {useEffect} from "react";

const TermsAndConditions = () => {

    useEffect(() => {
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    return (
        <div className="container-fluid">
            <div className="row bg-interface-color font-lato">
                <div className="col-12">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12">
                            <NavbarComponent/>
                        </div>
                    </div>
                    <div className={"row justify-content-center"}>
                        <div className={"col-11 col-md-10"}>
                            {/* START ARTICLE FEED */}
                            <div className="columns p-10 is-vcentered mt-5">
                                <div className="column is-full">

                                    <strong>Terms &amp; Conditions</strong>
                                    <p>
                                        These Terms of Use (“Terms of Use”) have been drafted in accordance with the
                                        provisions of Rule 3 (1) of the Information Technology Rules, 2011 that require
                                        publishing the rules and regulations, privacy policy and Terms of Use for access
                                        or usage of https://www.7dotss.com/
                                    </p>

                                </div>
                                <div className="column is-full">
                                    <strong>About</strong>
                                    <p>
                                        The domain name https://www.7dotss.com/ (hereinafter referred to as &quot
                                        Website)
                                        owned and managed by 7 Dots Smart Solutions (OPC) Private Limited, a one person
                                        private
                                        limited company limited by shares incorporated in India under the Companies Act,
                                        2013, and
                                        having its registered office at 2-37/53, 3rd floor, near Vijetha Super Market,
                                        Vinayak Nagar,
                                        Indira Nagar, Gachibowli, Hyderabad, Telangana - 500032 (hereinafter referred to
                                        as &quot Company).
                                        The Company basically designs and develops across all platforms from web apps
                                        and online tools to mobile apps, software.
                                    </p>
                                </div>
                                <div className="column is-full">
                                    <strong>Terms of Use</strong>
                                    <p>
                                        ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE INDICATES YOUR ACCEPTANCE TO
                                        ALL THE TERMS AND CONDITIONS HEREIN. PLEASE READ THESE TERMS OF USE CAREFULLY
                                        BEFORE PROCEEDING. IF YOU DO NOT AGREE WITH THE SAME, PLEASE DO NOT USE THIS
                                        WEBSITE.
                                    </p>
                                    <p>
                                        For the purpose of these Terms of Use, wherever the context so requires "You" or
                                        "User" shall mean any natural or legal person visits our platform, either just
                                        for the purpose of browsing the Website or engages to buy our products &
                                        services.
                                        This Website allows the User to surf the Website. The term "We", "Us", "Our"
                                        shall
                                        mean Company.
                                    </p>
                                    <p>
                                        Your use of the Website and services and tools are governed by the following
                                        terms
                                        and conditions ("Terms of Use") as applicable to the Website including the
                                        applicable
                                        policies which are incorporated herein by way of reference. If You transact on
                                        the
                                        Website, You shall be subject to the policies that are applicable to the Website
                                        for
                                        such transaction. By mere use of the Website, You shall be contracting with
                                        Company and
                                        these terms and conditions including the policies constitute your binding
                                        obligations,
                                        with the Website. Any new features or tools which are added to the current store
                                        shall
                                        also be subject to the Terms of Use. We reserve the right to update, change or
                                        replace
                                        any part of these Terms of Use by posting updates and/or changes to Our Website.
                                        It is
                                        your responsibility to check this page periodically for changes.
                                    </p>
                                </div>

                                <div className="column is-full">
                                    <strong>Eligibility to Use</strong>
                                    <p>
                                        By agreeing to these Terms of Use, you represent that you are persons who can
                                        form
                                        legally binding contracts under Indian Contract Act, 1872. Persons who are
                                        "incompetent
                                        to contract" within the meaning of the Indian Contract Act, 1872 including
                                        minors,
                                        un-discharged insolvents etc. are not eligible to use the Website. As a minor,
                                        if you
                                        wish to use or transact on Website, such use or transaction may be made by your
                                        legal
                                        guardian or parents on the Website. Company reserves the right to refuse You
                                        access to
                                        the Website if it is brought to Company &aposs notice or if it is discovered
                                        that you
                                        are under the age of 18 years. By visiting Company Website or accepting these
                                        Terms of Use, You represent and warrant to Company that You are 18 years of age
                                        or
                                        older and that You have the right, authority and capacity to use the Website and
                                        agree
                                        to and abide by these Terms of Use. You also represent and warrant to the
                                        Company that
                                        You will use the Website in a manner consistent with any and all applicable laws
                                        and regulations.
                                    </p>
                                </div>

                                <div className="column is-full">
                                    <strong>Privacy and Information Protection</strong>
                                    <p>
                                        ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE INDICATES YOUR ACCEPTANCE
                                        TO ALL THE TERMS AND CONDITIONS HEREIN. PLEASE READ THESE TERMS OF USE CAREFULLY
                                        BEFORE PROCEEDING. IF YOU DO NOT AGREE WITH THE SAME, PLEASE DO NOT USE THIS
                                        WEBSITE.
                                    </p>
                                    <p>
                                        Please review our Privacy Policy, which also governs your visit to this Site,
                                        to understand our internal policies and practices. The personal information/data
                                        provided to us by you during the course of usage of this website will be treated
                                        as strictly confidential and in accordance with the Privacy Policy and
                                        applicable
                                        laws and regulations. If You object to your information being transferred or
                                        used,
                                        please do not use the website.
                                    </p>
                                </div>
                                <div className="column is-full">
                                    <strong>Rights of use</strong>
                                    <p>
                                        Company grants you limited rights to access and make personal use of this
                                        website,
                                        but not to download (other than page caching) or modify it, or any portion of
                                        it.
                                        These rights do not include any commercial use of this website or its contents;
                                        any
                                        collection and use of any content, descriptions, or prices; any derivative use
                                        of this
                                        website or its contents; any downloading or copying of account information for
                                        the
                                        benefit of a third-party; or any use of data mining, robots, or similar data
                                        gathering
                                        and extraction tools. This website or any portion of this website (including but
                                        not
                                        limited to any copyrighted material, trademarks, or other proprietary
                                        information) may
                                        not be reproduced, duplicated, copied, sold, resold, visited, distributed or
                                        otherwise
                                        exploited for any commercial purpose.
                                    </p>
                                </div>

                                <div className="column is-full">
                                    <strong>Your Conduct</strong>
                                    <p>
                                        You must not use the Website in any way that causes, or is likely to cause,
                                        the Website or access to it to be interrupted, damaged or impaired in any way.
                                        You understand that you, and not Company, are responsible for all electronic
                                        communications and content sent from your computer to us and you must use the
                                        Website
                                        for lawful purposes only. You must not use the Website for any of the following:
                                    </p>
                                    <p>
                                        For Fraudulent Purposes, Or In Connection With A Criminal Offense Or Other
                                        Unlawful Activity;
                                        To Send, Use Or Reuse Any Material That Does Not Belong To You; Or Is Illegal,
                                        Offensive
                                        (Including But Not Limited To Material That Is Sexually Explicit Content Or
                                        Which Promotes
                                        Racism, Bigotry, Hatred Or Physical Harm), Deceptive, Misleading, Abusive,
                                        Indecent, Harassing,
                                        Blasphemous, Defamatory, Libelous, Obscene, Pornographic, Pedophilic Or
                                        Menacing;
                                        Ethnically Objectionable, Disparaging Or In Breach Of Copyright, Trademark,
                                        Confidentiality,
                                        Privacy Or Any Other Proprietary Information Or Right; Or Is Otherwise Injurious
                                        To Third Parties;
                                        Or Relates To Or Promotes Money Laundering Or Gambling; Or Is Harmful To Minors
                                        In Any Way;
                                        Or Impersonates Another Person; Or Threatens The Unity, Integrity, Security Or
                                        Sovereignty Of
                                        India Or Friendly Relations With Foreign States; Or Objectionable Or Otherwise
                                        Unlawful
                                        In Any Manner Whatsoever; Or Which Consists Of Or Contains Software Viruses,
                                        Political
                                        Campaigning, Commercial Solicitation, Chain Letters, Mass Mailings Or Any
                                        "Spam”;
                                        Or Violates Any Law For The Time Being In Force; Or Deceives Or Misleads
                                        The Addressee About The Origin Of Such Messages Or Communicates Any Information
                                        Which
                                        Is Grossly Offensive Or Menacing In Nature;
                                        To Not Make Any Unauthorized Commercial Use Of This Website Or Service And You
                                        May Not
                                        Resell This Service Or Assign Your Rights Or Obligations Under These Terms Of
                                        Usage.
                                        You May Not Reverse Engineer Any Part Of This Website Or Service.
                                        For Any Act That Causes, Or May Be Likely To Cause Damage Or Impairment To The
                                        Website
                                        Or In Any Manner Harms The Company Or Any Other Person Or Entity (As Determined
                                        By The
                                        Company In Its Sole Discretion) Or Interrupt Free Access To It In Any Way.
                                        Medical Records And History
                                        Biometric Information
                                        Information Received By Body Corporate Under Lawful Contract Or Otherwise
                                        Visitor Details As Provided At The Time Of Registration Or Thereafter And
                                        Call Data Records.
                                    </p>
                                </div>
                                <div className="column is-full">
                                    <strong>Our Policies</strong>
                                    <p>
                                        Disclaimer of Warranties and Liabilities
                                        We expressly disclaim any warranties or representations (express or implied)
                                        in respect of quality, suitability, accuracy, reliability, completeness,
                                        timeliness,
                                        performance, safety, merchantability, fitness for a particular purpose, or
                                        legality of
                                        the products listed or displayed or the content (including product information
                                        and/or
                                        specifications) on the Website. While we have taken precautions to avoid
                                        inaccuracies
                                        in content, this Website, all content, information, software, products, services
                                        &
                                        related graphics are provided as is, without warranty of any kind.
                                    </p>
                                </div>
                                <div className="column is-full">
                                    <strong>Indemnity and Release</strong>
                                    <p>
                                        You shall indemnify and hold harmless Company, its subsidiaries, affiliates and
                                        their
                                        respective officers, directors, agents and employees, from any claim or demand,
                                        or
                                        actions including reasonable attorney &apos s fees, made by any third party or
                                        penalty
                                        imposed due to or arising out of your breach of these Conditions of Use or any
                                        document
                                        incorporated by reference, or your violation of any law, rules, regulations or
                                        the
                                        rights of a third party.
                                    </p>
                                    <p>
                                        You hereby expressly release Company and/or its affiliates and/or any of its
                                        officers
                                        and representatives from any cost, damage, liability or other consequence of any
                                        of the
                                        actions/inactions of the vendors and specifically waiver any claims or demands
                                        that you
                                        may have in this behalf under any statute, contract or otherwise.
                                    </p>
                                </div>

                                <div className="column is-full">
                                    <strong>Communications</strong>
                                    <p>
                                        When you visit https://www.7dotss.com/ or send e-mails to us, you are
                                        communicating
                                        with us electronically. We may communicate with you by e-mail, SMS, phone call
                                        or
                                        by posting notices on the website or by any other mode of communication. For
                                        contractual purposes, you consent to receive communications including SMS,
                                        e-mail or phone calls from us.
                                    </p>
                                </div>

                                <div className="column is-full">
                                    <strong>Losses</strong>
                                    <p>
                                        We will not be responsible for any business loss (including loss of profits,
                                        revenue, contracts, anticipated savings, data, goodwill or wasted expenditure)
                                        or any other indirect or consequential loss that is not reasonably foreseeable
                                        to
                                        both you and us when you commenced using the website.
                                    </p>
                                </div>

                                <div className="column is-full">
                                    <strong>Alteration of Service or Amendments to the Conditions</strong>
                                    <p>
                                        We reserve the right to make changes to our website, policies, and these
                                        Conditions of Use at any time. You will be subject to the policies and
                                        Conditions of Use in force at the time that you use the website, unless any
                                        change to those policies or these conditions is required to be made by law or
                                        government authority. If any of these conditions is deemed invalid, void, or for
                                        any reason unenforceable, that condition will be deemed severable and will not
                                        affect the validity and enforceability of any remaining condition.
                                    </p>
                                </div>

                                <div className="column is-full">
                                    <strong>Events beyond our reasonable control</strong>
                                    <p>
                                        We will not be held responsible for any delay or failure to comply with our
                                        obligations under these conditions if the delay or failure arises from any cause
                                        which is beyond our reasonable control. This condition does not affect your
                                        statutory rights.
                                    </p>
                                </div>

                                <div className="column is-full">
                                    <strong>Waiver</strong>
                                    <p>
                                        If you breach these conditions and we take no action, we will still be entitled
                                        to use our rights and remedies in any other situation where you breach these
                                        conditions.
                                    </p>
                                </div>

                                <div className="column is-full">
                                    <strong>Governing law and Jurisdiction</strong>
                                    <p>
                                        These conditions are governed by and construed in accordance with the laws of
                                        India. You agree, as we do, to submit to the exclusive jurisdiction of the
                                        courts at Hyderabad.
                                    </p>
                                    <p>
                                        IN NO EVENT SHALL 7 DOTS SMART SOLUTIONS (OPC) PVT LTD BE LIABLE FOR ANY
                                        SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION
                                        WITH THESE TERMS OF USE, EVEN IF USER HAS BEEN INFORMED IN ADVANCE OF THE
                                        POSSIBILITY OF SUCH DAMAGES.
                                    </p>
                                </div>

                                <div className="column is-full">
                                    <strong>Severability & Waiver Contact Information</strong>
                                    <p>
                                        If any provision of this Terms of Use is held to be invalid or unenforceable,
                                        such provision shall be struck and the remaining provisions shall be enforced.
                                    </p>
                                </div>

                                <div className="column is-full" id="contact">
                                    <strong>Contact Information</strong>
                                    <p>
                                        This site is owned and operated by 7 Dots Smart Solutions (OPC) Private Limited.
                                        If You have any have any question, issue, complaint regarding any of our
                                        Services, please contact <a
                                        href="mailto:business@7dotss.com">business@7dotss.com</a> or
                                        7dotssmartsolutions@gmail.com
                                    </p>
                                </div>
                                {/* END ARTICLE FEED */}
                            </div>
                        </div>
                    </div>
                    <div className="row bg-interface-color font-lato">
                        <div className="col-12 ">
                            <div
                                className="row d-flex justify-content-center mt-4 border-bottom border-grey-3 mt-5"></div>
                        </div>
                    </div>
                    <FooterComponent/>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditions;
