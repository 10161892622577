import FooterComponent from "../FooterComponent/footerComponent";
import NavbarComponent from "../NavbarComponent/navbarComponent";
import ContactUs from "../ContactUs/contactUs";
import {Swiper, SwiperSlide,} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import {Autoplay} from "swiper/modules";
import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {logEvent} from 'firebase/analytics';
import {analytics} from "../FirebaseAuthentication/firebase";

const LandingPage = () => {

    const [swiper, setActiveSwiper] = useState(null);
    const location = useLocation();

    const goReviewNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goReviewPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    const handleOnMouseEnter = (id) => {
        const idSwiper = document.getElementById(id);
        if (idSwiper) {
            idSwiper.swiper.params.autoplay.delay = 500;
            idSwiper.swiper.autoplay.start(); // Start autoplay
        }
    };

    const handleOnMouseLeave = (id) => {
        const idSwiper = document.getElementById(id);
        if (idSwiper) {
            const swiperInstance = idSwiper.swiper;
            swiperInstance.autoplay.stop(); // Stop autoplay
            swiperInstance.slideTo(0); // Stop autoplay
        }
    };

    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_title: 'Home Page',
            page_location: window.location.href,
            page_path: location.pathname,
        });
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    return (
        <div className="container-fluid">
            {/*Hero section and navbar*/}
            <div className="row bg-interface-color font-lato pb-md-4 pb-2 justify-content-center align-items-center">
                <div className="col-12 pb-md-2">
                    {/*Navbar section*/}
                    <div className="row d-flex justify-content-center">
                        <div className="col-12">
                            <NavbarComponent/>
                        </div>
                    </div>
                    {/*Hero section*/}
                    <div
                        className="row d-flex justify-content-center res-text-align-center align-items-center">
                        <div
                            className="col-12 col-md-12 d-flex justify-content-center mt-3 res-text-align-center align-items-center">
                            <Swiper
                                className={"pb-3"}
                                slidesPerView={1}
                                autoplay={true}
                                navigation={false}
                                speed={800}
                                spaceBetween={10}
                                loop={true}
                                freeMode={true}
                                modules={[Autoplay]}
                            >
                                <SwiperSlide>
                                    <div className="row justify-content-center align-items-center">
                                        <div
                                            className="col-12 col-md-5 order-md-0 order-1 justify-content-center align-items-center p-4">
                                            <div
                                                className="row d-flex flex-column justify-content-center align-items-start">
                                                <div className="col-12 p-0 d-flex justify-content-center">
                                                    <label
                                                        className="font-georgia font-size-40 font-weight-700 text-color-grey-dark">
                                                        Build digital products with experts in design and technology.
                                                    </label>
                                                </div>
                                                <div className="mb-4 pt-3 px-0 col-12">
                                                    <label className="font-size-20 font-weight-400 text-color-grey-1">
                                                        We have the best UX Design and Development and digital marketing
                                                        team
                                                        that translates dreams to reality.
                                                    </label>
                                                </div>
                                                <div className={"col-12 p-0"}>
                                                    <a href="#contact">
                                                        <button type="button"
                                                                className="col-md-4 col-12 bg-indigo button-hire-us font-size-24 text-white border-none mt-2 mt-md-4">
                                                            Hire us
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 col-md-5 d-flex justify-content-center align-items-center">
                                            <div className="d-flex flex-column justify-content-center">
                                                <img className="img-fluid" src="images/landing/Hero_section_image.png"
                                                     alt="hero section"/>
                                                {/*<div className="row d-none d-md-flex justify-content-center gap-4">*/}
                                                {/*    <div*/}
                                                {/*        className="col-3 col-md-3 our-details-container d-flex flex-column justify-content-center align-items-center justify-content-center border-none">*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-24 font-weight-700 text-color-grey-dark">*/}
                                                {/*            20+*/}
                                                {/*        </label>*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-14 text-center font-weight-400 text-color-grey-1">*/}
                                                {/*            Completed Projects*/}
                                                {/*        </label>*/}
                                                {/*    </div>*/}
                                                {/*    <div*/}
                                                {/*        className="col-3 col-md-3 our-details-container d-flex flex-column align-items-center justify-content-center border-none ">*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-24 font-weight-700 text-color-grey-dark">*/}
                                                {/*            4+*/}
                                                {/*        </label>*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-14 font-weight-400 text-color-grey-1">*/}
                                                {/*            Experience*/}
                                                {/*        </label>*/}
                                                {/*    </div>*/}
                                                {/*    <div*/}
                                                {/*        className="col-3 col-md-3 our-details-container d-flex flex-column align-items-center justify-content-center border-none ">*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-24 font-weight-700 text-color-grey-dark">*/}
                                                {/*            6*/}
                                                {/*        </label>*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-14 text-center font-weight-400 text-color-grey-1">*/}
                                                {/*            Active projects*/}
                                                {/*        </label>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="row justify-content-center align-items-center">
                                        <div
                                            className="col-12 col-md-5 order-md-0 order-1 justify-content-center align-items-center p-4">
                                            <div
                                                className="row d-flex flex-column justify-content-center align-items-start">
                                                <div
                                                    className="col-12 p-0 d-flex justify-content-center align-items-start">
                                                    <label
                                                        className="font-georgia font-size-40 font-weight-700 text-color-grey-dark">
                                                        One-stop solution for digital excellence.
                                                    </label>
                                                </div>
                                                <div className="mb-4 pt-3 px-0 col-12">
                                                    <label className="font-size-20 font-weight-400 text-color-grey-1">
                                                        We have the best UX Design and Development and digital marketing
                                                        team
                                                        that translates dreams to reality.
                                                    </label>
                                                </div>
                                                <div className={"col-12 p-0"}>
                                                    <a href="#contact">
                                                        <button type="button"
                                                                className="col-md-4 col-12 bg-indigo button-hire-us font-size-24 text-white border-none mt-2 mt-md-4">
                                                            Contact us
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 col-md-5 d-flex justify-content-center align-items-center">
                                            <div className="d-flex flex-column justify-content-center">
                                                <img className="img-fluid bg-transparent"
                                                     src="images/landing/home-page-hero-section-image-2.png"
                                                     alt="hero section"/>
                                                {/*<div className="row d-none d-md-flex justify-content-center gap-4">*/}
                                                {/*    <div*/}
                                                {/*        className="col-3 col-md-3 our-details-container d-flex flex-column justify-content-center align-items-center justify-content-center border-none">*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-24 font-weight-700 text-color-grey-dark">*/}
                                                {/*            20+*/}
                                                {/*        </label>*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-14 text-center font-weight-400 text-color-grey-1">*/}
                                                {/*            Completed Projects*/}
                                                {/*        </label>*/}
                                                {/*    </div>*/}
                                                {/*    <div*/}
                                                {/*        className="col-3 col-md-3 our-details-container d-flex flex-column align-items-center justify-content-center border-none ">*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-24 font-weight-700 text-color-grey-dark">*/}
                                                {/*            4+*/}
                                                {/*        </label>*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-14 font-weight-400 text-color-grey-1">*/}
                                                {/*            Experience*/}
                                                {/*        </label>*/}
                                                {/*    </div>*/}
                                                {/*    <div*/}
                                                {/*        className="col-3 col-md-3 our-details-container d-flex flex-column align-items-center justify-content-center border-none ">*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-24 font-weight-700 text-color-grey-dark">*/}
                                                {/*            6*/}
                                                {/*        </label>*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-14 text-center font-weight-400 text-color-grey-1">*/}
                                                {/*            Active projects*/}
                                                {/*        </label>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="row justify-content-center align-items-center">
                                        <div
                                            className="col-12 col-md-5 order-md-0 order-1 justify-content-center align-items-center p-4">
                                            <div
                                                className="row d-flex flex-column justify-content-center align-items-start">
                                                <div className="col-12 p-0 d-flex justify-content-center">
                                                    <label
                                                        className="font-georgia font-size-40 font-weight-700 text-color-grey-dark">
                                                        Excel in business with cutting-edge software design expertise.
                                                    </label>
                                                </div>
                                                <div className="mb-4 pt-3 px-0 col-12">
                                                    <label className="font-size-20 font-weight-400 text-color-grey-1">
                                                        We have the best UX Design and Development and digital marketing
                                                        team
                                                        that translates dreams to reality.
                                                    </label>
                                                </div>
                                                <div className={"col-12 p-0"}>
                                                    <a href="#contact">
                                                        <button type="button"
                                                                className="col-md-4 col-12 bg-indigo button-hire-us font-size-24 text-white border-none mt-2 mt-md-4">
                                                            Hire us
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 col-md-5 d-flex justify-content-center align-items-center">
                                            <div className="d-flex flex-column justify-content-center">
                                                <img className="img-fluid"
                                                     src="images/landing/home-page-hero-section-image-3.png"
                                                     alt="hero section"/>
                                                {/*<div className="row d-none d-md-flex justify-content-center gap-4">*/}
                                                {/*    <div*/}
                                                {/*        className="col-3 col-md-3 our-details-container d-flex flex-column justify-content-center align-items-center justify-content-center border-none">*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-24 font-weight-700 text-color-grey-dark">*/}
                                                {/*            20+*/}
                                                {/*        </label>*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-14 text-center font-weight-400 text-color-grey-1">*/}
                                                {/*            Completed Projects*/}
                                                {/*        </label>*/}
                                                {/*    </div>*/}
                                                {/*    <div*/}
                                                {/*        className="col-3 col-md-3 our-details-container d-flex flex-column align-items-center justify-content-center border-none ">*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-24 font-weight-700 text-color-grey-dark">*/}
                                                {/*            4+*/}
                                                {/*        </label>*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-14 font-weight-400 text-color-grey-1">*/}
                                                {/*            Experience*/}
                                                {/*        </label>*/}
                                                {/*    </div>*/}
                                                {/*    <div*/}
                                                {/*        className="col-3 col-md-3 our-details-container d-flex flex-column align-items-center justify-content-center border-none ">*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-24 font-weight-700 text-color-grey-dark">*/}
                                                {/*            6*/}
                                                {/*        </label>*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-14 text-center font-weight-400 text-color-grey-1">*/}
                                                {/*            Active projects*/}
                                                {/*        </label>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="row justify-content-center align-items-center">
                                        <div
                                            className="col-12 col-md-5 order-md-0 order-1 justify-content-center align-items-center p-4">
                                            <div
                                                className="row d-flex flex-column justify-content-center align-items-start">
                                                <div className="col-12 p-0 d-flex justify-content-center">
                                                    <label
                                                        className="font-georgia font-size-40 font-weight-700 text-color-grey-dark">
                                                        Turn social media followers into valuable customers.
                                                    </label>
                                                </div>
                                                <div className="mb-4 pt-3 px-0 col-12">
                                                    <label className="font-size-20 font-weight-400 text-color-grey-1">
                                                        We have the best UX Design and Development and digital marketing
                                                        team
                                                        that translates dreams to reality.
                                                    </label>
                                                </div>
                                                <div className={"col-12 p-0"}>
                                                    <a href="#contact">
                                                        <button type="button"
                                                                className="col-md-4 col-12 bg-indigo button-hire-us font-size-24 text-white border-none mt-2 mt-md-4">
                                                            Contact us
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 col-md-5 d-flex justify-content-center align-items-center">
                                            <div className="d-flex flex-column justify-content-center">
                                                <img className="img-fluid"
                                                     src="images/landing/home-page-hero-section-image-4.png"
                                                     alt="hero section"/>
                                                {/*<div className="row d-none d-md-flex justify-content-center gap-4">*/}
                                                {/*    <div*/}
                                                {/*        className="col-3 col-md-3 our-details-container d-flex flex-column justify-content-center align-items-center justify-content-center border-none">*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-24 font-weight-700 text-color-grey-dark">*/}
                                                {/*            20+*/}
                                                {/*        </label>*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-14 text-center font-weight-400 text-color-grey-1">*/}
                                                {/*            Completed Projects*/}
                                                {/*        </label>*/}
                                                {/*    </div>*/}
                                                {/*    <div*/}
                                                {/*        className="col-3 col-md-3 our-details-container d-flex flex-column align-items-center justify-content-center border-none ">*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-24 font-weight-700 text-color-grey-dark">*/}
                                                {/*            4+*/}
                                                {/*        </label>*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-14 font-weight-400 text-color-grey-1">*/}
                                                {/*            Experience*/}
                                                {/*        </label>*/}
                                                {/*    </div>*/}
                                                {/*    <div*/}
                                                {/*        className="col-3 col-md-3 our-details-container d-flex flex-column align-items-center justify-content-center border-none ">*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-24 font-weight-700 text-color-grey-dark">*/}
                                                {/*            6*/}
                                                {/*        </label>*/}
                                                {/*        <label*/}
                                                {/*            className="font-size-14 text-center font-weight-400 text-color-grey-1">*/}
                                                {/*            Active projects*/}
                                                {/*        </label>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>

            {/* our projects */}
            <div className="row bg-interface-color justify-content-center pb-md-5 d-flex justify-content-around">
                <div className={"col-10 mb-4 pb-2"}>
                    <div className={"w-100 d-flex justify-content-center"}>
                        <label className={"font-lato font-weight-700 font-size-32 text-color-black"}>Our Results</label>
                    </div>
                    <div
                        className={"row our-details-container d-flex justify-content-between align-items-center mt-md-4 pt-3"}>
                        <div
                            className="col-3 col-md-3 d-flex flex-column justify-content-center align-items-center border-none">
                            <label
                                className="font-size-72 font-georgia font-weight-700 text-indgo">
                                40+
                            </label>
                            <label
                                className="font-size-18 text-center font-weight-500 text-color-grey-dark">
                                Completed Projects
                            </label>
                        </div>
                        <div
                            className="col-3 col-md-3 d-flex flex-column align-items-center justify-content-center border-none ">
                            <label
                                className="font-size-72 font-georgia font-weight-700 text-indgo">
                                4+
                            </label>
                            <label
                                className="font-size-18 text-center font-weight-500 text-color-grey-dark">
                                Experience
                            </label>
                        </div>
                        <div
                            className="col-3 col-md-3 d-flex flex-column align-items-center justify-content-center border-none ">
                            <label
                                className="font-size-72 font-georgia font-weight-700 text-indgo">
                                6
                            </label>
                            <label
                                className="font-size-18 text-center font-weight-500 text-color-grey-dark">
                                Active projects
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            {/*our works*/}
            <div
                className="row bg-white d-flex flex-column justify-content-center font-lato align-items-center mb-4 p-md-4">
                <div className="col-12 col-md-10 mb-md-4">
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-center">
                                <label className="font-size-48 text-color-black font-weight-700 font-lato">
                                    Our Works
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2 py-4 pb-md-0">
                        <div className="col-12 col-md-6 mt-md-4 mt-1 pt-2">
                            <div className="web-app-background br-10 h-100 d-flex flex-column justify-content-end">
                                <div className="py-4 my-4 d-block d-md-none"></div>
                                <div className="py-4 my-4 d-block d-md-none"></div>
                                <div className=" p-4">
                                    <label className="text-color-white font-size-40 font-weight-700">
                                        Website & App Design
                                    </label>
                                    <label className="text-color-white font-size-20">
                                        Our proven processes have brought us numerous successes. We are excited to be
                                        recognized as a leading UI/UX Design Agency. Our dedication to delivering
                                        high-quality website design, Mobile application design has made us the best
                                        software design company globally and inspires us to strive for greater
                                        achievements each day.
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <div className="row">
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("web-design-slide-1")}
                                         onMouseLeave={() => handleOnMouseLeave("web-design-slide-1")}>
                                        <Swiper
                                            id={"web-design-slide-1"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100" id="web-app-design-beebloom"
                                                     src="images/landing/our-works-beebloom-image.png" alt="Beebloom"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-beebloom-image"
                                                     src="images/landing/our-works-event-replay-image.png"
                                                     alt="Beebloom"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-beebloom-image"
                                                     src="images/landing/our-works-beebloom-image.png" alt="Beebloom"/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Beebloom</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("web-design-slide-2")}
                                         onMouseLeave={() => handleOnMouseLeave("web-design-slide-2")}>
                                        <Swiper
                                            id={"web-design-slide-2"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid bg-images-linear-gradient w-100 br-10"
                                                     id="web-app-event-replay"
                                                     src="images/landing/our-works-event-reply-display-image.png"
                                                     alt="event replay"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img
                                                    className="img-fluid bg-images-linear-gradient w-100 br-10 web-app-event-replay-image"
                                                    src="images/landing/our-works-event-replay-image.png"
                                                    alt="event replay"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img
                                                    className="img-fluid bg-images-linear-gradient w-100 br-10 web-app-event-replay-image"
                                                    src="images/landing/our-works-event-reply-display-image.png"
                                                    alt="event replay"/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Eventreply</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("web-design-slide-3")}
                                         onMouseLeave={() => handleOnMouseLeave("web-design-slide-3")}>
                                        <Swiper
                                            id={"web-design-slide-3"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100" id="web-app-dating"
                                                     src="images/landing/our-works-dating-display-image.png"
                                                     alt="dating display "/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-dating-gallery"
                                                     src="images/landing/our-works-dating-image.png" alt="dating "/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-dating-gallery"
                                                     src="images/landing/our-works-dating-display-image.png"
                                                     alt="dating display "/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Dating</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("web-design-slide-4")}
                                         onMouseLeave={() => handleOnMouseLeave("web-design-slide-4")}>
                                        <Swiper
                                            id={"web-design-slide-4"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100" id="web-app-fuel-desk"
                                                     src="images/landing/our-works-fuel-desk-display-image.png"
                                                     alt="fuel desk display "/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-fuel-desk-image"
                                                     src="images/landing/our-works-fuel-desk-image.png"
                                                     alt="fuel desk"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-fuel-desk-image"
                                                     src="images/landing/our-works-fuel-desk-display-image.png"
                                                     alt="fuel desk display "/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Fuel desk</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to="/website-app-design">
                                <button type="button"
                                        className="col-12 font-size-20 bg-transparent button-see-all text-color-primary font-weight-700 mt-4">
                                    See all
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="row mt-4 py-md-4 pb-1 pb-md-0">
                        <div className="col-12 col-md-6 mt-md-4 order-md-1">
                            <div className="web-site-background br-10 h-100 d-flex flex-column justify-content-end">
                                <div className="py-4 my-4 d-block d-md-none"></div>
                                <div className="py-4 my-4 d-block d-md-none"></div>
                                <div className="p-3 p-md-4">
                                    <label className="text-color-white font-size-40 font-weight-700">
                                        Website & App Development
                                    </label>
                                    <label className="text-color-white font-size-20">
                                        We partner worldwide to build Premium Software Products. Whether you are a
                                        budding startup or an established enterprise, we will help you to develop
                                        websites, mobile apps, or other software solutions. When it’s matter of best
                                        website designers or best mobile application design then we’re end-to-end
                                        solutions for your most ambitious projects.
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="row">
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("app-development-slide-1")}
                                         onMouseLeave={() => handleOnMouseLeave("app-development-slide-1")}>
                                        <Swiper
                                            id={"app-development-slide-1"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100" id="web-app-design-beebloom"
                                                     src="images/landing/our-works-beebloom-image.png" alt="Beebloom"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-beebloom-image"
                                                     src="images/landing/our-works-event-replay-image.png"
                                                     alt="Beebloom"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-beebloom-image"
                                                     src="images/landing/our-works-beebloom-image.png" alt="Beebloom"/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Beebloom</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("app-development-slide-2")}
                                         onMouseLeave={() => handleOnMouseLeave("app-development-slide-2")}>
                                        <Swiper
                                            id={"app-development-slide-2"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid bg-images-linear-gradient w-100 br-10"
                                                     id="web-app-event-replay"
                                                     src="images/landing/our-works-event-reply-display-image.png"
                                                     alt="event replay"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img
                                                    className="img-fluid bg-images-linear-gradient w-100 br-10 web-app-event-replay-image"
                                                    src="images/landing/our-works-event-replay-image.png"
                                                    alt="event replay"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img
                                                    className="img-fluid bg-images-linear-gradient w-100 br-10 web-app-event-replay-image"
                                                    src="images/landing/our-works-event-reply-display-image.png"
                                                    alt="event replay"/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Eventreply</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("app-development-slide-3")}
                                         onMouseLeave={() => handleOnMouseLeave("app-development-slide-3")}>
                                        <Swiper
                                            id={"app-development-slide-3"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100" id="web-app-dating"
                                                     src="images/landing/our-works-dating-display-image.png"
                                                     alt="dating display "/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-dating-gallery"
                                                     src="images/landing/our-works-dating-image.png" alt="dating "/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-dating-gallery"
                                                     src="images/landing/our-works-dating-display-image.png"
                                                     alt="dating display "/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Dating</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("app-development-slide-4")}
                                         onMouseLeave={() => handleOnMouseLeave("app-development-slide-4")}>
                                        <Swiper
                                            id={"app-development-slide-4"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100" id="web-app-fuel-desk"
                                                     src="images/landing/our-works-fuel-desk-display-image.png"
                                                     alt="fuel desk display "/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-fuel-desk-image"
                                                     src="images/landing/our-works-fuel-desk-image.png"
                                                     alt="fuel desk"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-fuel-desk-image"
                                                     src="images/landing/our-works-fuel-desk-display-image.png"
                                                     alt="fuel desk display "/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Fuel desk</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to={"/website-app-development"}>
                                <button type="button"
                                        className="col-12 font-size-20 bg-transparent button-see-all text-color-primary font-weight-700 mt-4">
                                    See all
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="row py-md-4 mt-4 pb-1 pb-md-0">
                        <div className="col-12 col-md-6 mt-4">
                            <div
                                className="web-app-content-background br-10 h-100 w-100 d-flex flex-column justify-content-end">
                                <div className="py-4 my-4 d-block d-md-none"></div>
                                <div className="py-4 my-4 d-block d-md-none"></div>
                                <div className=" p-4">
                                    <label className="text-color-white font-size-40 font-weight-700">
                                        Content Writing
                                    </label>
                                    <label className="text-color-white font-size-20">
                                        We attract, convert and retain your customers efficiently to reach your growth
                                        goals. And we do it 2.6x faster than the industry average with our catchy
                                        content, strategic social media marketing. Dive into a partnership where your
                                        success is our mission. we're not just part of your journey - we're your
                                        co-pilots to success.
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className={"row"}>
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("content-writing-slide-1")}
                                         onMouseLeave={() => handleOnMouseLeave("content-writing-slide-1")}>
                                        <Swiper
                                            id={"content-writing-slide-1"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100" id="web-app-design-beebloom"
                                                     src="images/landing/our-works-beebloom-image.png" alt="Beebloom"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-beebloom-image"
                                                     src="images/landing/our-works-event-replay-image.png"
                                                     alt="Beebloom"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-beebloom-image"
                                                     src="images/landing/our-works-beebloom-image.png" alt="Beebloom"/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20 font-lato">Beebloom</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("content-writing-slide-2")}
                                         onMouseLeave={() => handleOnMouseLeave("content-writing-slide-2")}>
                                        <Swiper
                                            id={"content-writing-slide-2"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid bg-images-linear-gradient w-100 br-10"
                                                     id="web-app-event-replay"
                                                     src="images/landing/our-works-event-reply-display-image.png"
                                                     alt="event replay"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img
                                                    className="img-fluid bg-images-linear-gradient w-100 br-10 web-app-event-replay-image"
                                                    src="images/landing/our-works-event-replay-image.png"
                                                    alt="event replay"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img
                                                    className="img-fluid bg-images-linear-gradient w-100 br-10 web-app-event-replay-image"
                                                    src="images/landing/our-works-event-reply-display-image.png"
                                                    alt="event replay"/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Eventreply</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("content-writing-slide-3")}
                                         onMouseLeave={() => handleOnMouseLeave("content-writing-slide-3")}>
                                        <Swiper
                                            id={"content-writing-slide-3"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100" id="web-app-dating"
                                                     src="images/landing/our-works-dating-display-image.png"
                                                     alt="dating display "/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-dating-gallery"
                                                     src="images/landing/our-works-dating-image.png" alt="dating "/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-dating-gallery"
                                                     src="images/landing/our-works-dating-display-image.png"
                                                     alt="dating display "/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Dating</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("content-writing-slide-4")}
                                         onMouseLeave={() => handleOnMouseLeave("content-writing-slide-4")}>
                                        <Swiper
                                            id={"content-writing-slide-4"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100" id="web-app-fuel-desk"
                                                     src="images/landing/our-works-fuel-desk-display-image.png"
                                                     alt="fuel desk display "/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-fuel-desk-image"
                                                     src="images/landing/our-works-fuel-desk-image.png"
                                                     alt="fuel desk"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-fuel-desk-image"
                                                     src="images/landing/our-works-fuel-desk-display-image.png"
                                                     alt="fuel desk display "/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Fuel desk</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to={"/content-writing"}>
                                <button type="button"
                                        className="col-12 font-size-20 bg-transparent button-see-all text-color-primary font-weight-700 mt-4">
                                    See all
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="row mt-2 py-md-4 mt-4 pb-1 pb-md-0">
                        <div className="col-12 col-md-6 mt-md-4 order-md-1">
                            <div
                                className="graphic-design-background br-10 h-100 d-flex flex-column justify-content-end">
                                <div className="py-4 my-4 d-block d-md-none"></div>
                                <div className="py-4 my-4 d-block d-md-none"></div>
                                <div className="p-3 p-md-4">
                                    <label className="text-color-white font-size-40 font-weight-700">
                                        Graphic design
                                    </label>
                                    <label className="text-color-white font-size-20">
                                        Aim for design that connects with your audience. It should transcend all
                                        mediums, whether that’s print, social media content, or a marketing campaign.
                                        Best logo designers near me? Affordable graphic designers? We are the answer to
                                        all your design questions.
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className={"row"}>
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("graphic-design-slide-1")}
                                         onMouseLeave={() => handleOnMouseLeave("graphic-design-slide-1")}>
                                        <Swiper
                                            id={"graphic-design-slide-1"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100" id="web-app-design-beebloom"
                                                     src="images/landing/our-works-beebloom-image.png" alt="Beebloom"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-beebloom-image"
                                                     src="images/landing/our-works-event-replay-image.png"
                                                     alt="Beebloom"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-beebloom-image"
                                                     src="images/landing/our-works-beebloom-image.png" alt="Beebloom"/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Beebloom</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("graphic-design-slide-2")}
                                         onMouseLeave={() => handleOnMouseLeave("graphic-design-slide-2")}>
                                        <Swiper
                                            id={"graphic-design-slide-2"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid bg-images-linear-gradient w-100 br-10"
                                                     id="web-app-event-replay"
                                                     src="images/landing/our-works-event-reply-display-image.png"
                                                     alt="event replay"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img
                                                    className="img-fluid bg-images-linear-gradient w-100 br-10 web-app-event-replay-image"
                                                    src="images/landing/our-works-event-replay-image.png"
                                                    alt="event replay"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img
                                                    className="img-fluid bg-images-linear-gradient w-100 br-10 web-app-event-replay-image"
                                                    src="images/landing/our-works-event-reply-display-image.png"
                                                    alt="event replay"/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Eventreply</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("graphic-design-slide-3")}
                                         onMouseLeave={() => handleOnMouseLeave("graphic-design-slide-3")}>
                                        <Swiper
                                            id={"graphic-design-slide-3"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100" id="web-app-dating"
                                                     src="images/landing/our-works-dating-display-image.png"
                                                     alt="dating display "/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-dating-gallery"
                                                     src="images/landing/our-works-dating-image.png" alt="dating "/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-dating-gallery"
                                                     src="images/landing/our-works-dating-display-image.png"
                                                     alt="dating display "/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Dating</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3 mt-md-4">
                                    <div className="position-relative w-100"
                                         onMouseEnter={() => handleOnMouseEnter("graphic-design-slide-4")}
                                         onMouseLeave={() => handleOnMouseLeave("graphic-design-slide-4")}>
                                        <Swiper
                                            id={"graphic-design-slide-4"}
                                            autoplay={false}
                                            speed={500}
                                            modules={[Autoplay]}
                                            loop={true}
                                            spaceBetween={20}
                                            grabCursor={true}
                                            slidesPerView={1}
                                        >
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100" id="web-app-fuel-desk"
                                                     src="images/landing/our-works-fuel-desk-display-image.png"
                                                     alt="fuel desk display "/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-fuel-desk-image"
                                                     src="images/landing/our-works-fuel-desk-image.png"
                                                     alt="fuel desk"/>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img className="img-fluid br-10 w-100 web-app-fuel-desk-image"
                                                     src="images/landing/our-works-fuel-desk-display-image.png"
                                                     alt="fuel desk display "/>
                                            </SwiperSlide>
                                        </Swiper>
                                        <div className="position-absolute bottom-0 p-2 z-1">
                                            <label className="text-color-white font-size-20">Fuel desk</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to={"/graphic-design"}>
                                <button type="button"
                                        className="col-12 font-size-20 bg-transparent button-see-all text-color-primary font-weight-700 mt-4">
                                    See all
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/*Happy clients*/}
            <div
                className="row px-4 d-flex bg-interface-color font-lato flex-column justify-content-center align-items-center pb-4">
                <div className="col-12 mt-md-4">
                    <div className="row mt-4">
                        <div className="col-12 mt-md-4">
                            <div className="d-flex align-items-center justify-content-center mt-2">
                                <label
                                    className="font-size-48 font-lato font-weight-700 res-text-align-center res-font-size-24">
                                    Happy Clients
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 py-4 mb-4">
                        <div className="col-12 mt-2">
                            <Swiper
                                className={"pb-4"}
                                loop={true}
                                spaceBetween={20}
                                grabCursor={true}
                                slidesPerView={3}
                                autoplay={true}
                                breakpoints={{
                                    600: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                    },

                                    300: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                        coverflowEffect: {
                                            depth: 300
                                        }
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                    },
                                }}
                                modules={[Autoplay]}
                                onSwiper={setActiveSwiper}
                            >
                                <SwiperSlide>
                                    <div className="card br-10 box-shadow custom-card text-color-464646 w-100">
                                        <div className="col-2">
                                            <img className="img-fluid"
                                                 src="images/landing/quote-icon-inside-landing-page-card.png"
                                                 alt={"quotes"}/>
                                        </div>
                                        <div className="card-body text-center mt-4">
                                            <p className="card-text font-lato font-size-20 font-weight-400">
                                                “The 7dots team successfully delivered a functional website after
                                                consistent efforts since 2022. They stayed on task, on budget, and
                                                provided quick, courteous responses throughout the process, earning high
                                                praise for their professionalism and outstanding service.”
                                            </p>
                                        </div>
                                        <div className="text-center mt-4">
                                            <label className="font-lato font-size-20 font-weight-700">Arlene
                                                McCoy</label>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div
                                        className="card br-10 box-shadow custom-card text-color-464646">
                                        <div className="col-2">
                                            <img className="img-fluid"
                                                 src="images/landing/quote-icon-inside-landing-page-card.png"
                                                 alt={"quotes"}/>
                                        </div>
                                        <div className="card-body text-center mt-4">
                                            <p className="card-text font-lato font-size-20 font-weight-400">
                                                Thanks to 7dots, we successfully developed our website and mobile app on
                                                time. Their team’s professionalism, excellence, and hard work were
                                                exceptional. We are excited about collaborating with them again for
                                                future projects and highly recommend their service. </p>
                                        </div>
                                        <div className="text-center mt-4">
                                            <label className="font-lato font-size-20 font-weight-700">Rober
                                                Ivov</label>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div
                                        className="card br-10 box-shadow custom-card text-color-464646 ">
                                        <div className="col-2">
                                            <img className="img-fluid"
                                                 src="images/landing/quote-icon-inside-landing-page-card.png"
                                                 alt={"quotes"}/>
                                        </div>

                                        <div className="card-body text-center mt-4">
                                            <p className="card-text font-lato font-size-20 font-weight-400">
                                                The team delivered excellent SEO-friendly content and online marketing
                                                solutions for our website. Their professionalism, reliability, and
                                                honesty impressed us. We highly recommend them as an experienced digital
                                                agency capable of boosting your brand's online presence effectively.
                                            </p>
                                        </div>
                                        <div className="text-center mt-4">
                                            <label
                                                className="font-lato font-size-20 font-weight-700">Andrew</label>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="card br-10 box-shadow custom-card text-color-464646">
                                        <div className="col-2">
                                            <img className="img-fluid"
                                                 src="images/landing/quote-icon-inside-landing-page-card.png"
                                                 alt={"quotes"}/>
                                        </div>
                                        <div className="card-body text-center mt-4">
                                            <p className="card-text font-lato font-size-20 font-weight-400">
                                                I’m extremely happy with the logo designers’ creativity and expertise.
                                                They delivered a bold and innovative logo that aligns perfectly with my
                                                brand’s identity. Their professionalism makes them highly recommended
                                                for logo design projects, whether small or large.
                                            </p>
                                        </div>
                                        <div className="text-center mt-4">
                                            <label className="font-lato font-size-20 font-weight-700">R.C
                                                Smith</label>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="card br-10 box-shadow custom-card text-color-464646 ">
                                        <div className="col-2">
                                            <img className="img-fluid"
                                                 src="images/landing/quote-icon-inside-landing-page-card.png"
                                                 alt={"quotes"}/>
                                        </div>

                                        <div className="card-body text-center mt-4">
                                            <p className="card-text font-lato font-size-20 font-weight-400">
                                                Collaborating with 7dots on our graphic design project was fantastic.
                                                They precisely captured our vision in a stunning poster. Their
                                                creativity, professionalism, and customer-first approach impressed me,
                                                making me a lifelong customer for their exceptional quality and service.
                                            </p>
                                        </div>
                                        <div className="text-center mt-4">
                                            <label className="font-lato font-size-20 font-weight-700">Emily
                                                k.</label>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>

                            {/*<div className="" role="group" aria-label="Splide Basic HTML Example">*/}
                            {/*<div className="splide__track">*/}
                            {/*    <ul className="splide__list pb-4">*/}
                            {/*        <li className="splide__slide">*/}
                            {/*            <div className="">*/}
                            {/*                <div className="">*/}
                            {/*                    <div*/}
                            {/*                        className="card br-10 box-shadow custom-card text-color-grey-dark w-100">*/}
                            {/*                        <div className="col-2">*/}
                            {/*                            <img className="img-fluid"*/}
                            {/*                                 src="images/landing/quote-icon-inside-landing-page-card.png" alt={"quotes"}/>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="card-body text-center mt-4">*/}
                            {/*                            <p className="card-text font-lato font-size-20 font-weight-400">*/}
                            {/*                                “I've been trying to put together a functional website*/}
                            {/*                                since I*/}
                            {/*                                began my practice in April of 2022.*/}
                            {/*                                I am happy to say we finally hired the 7dots team and*/}
                            {/*                                they've*/}
                            {/*                                worked closely with us throughout the process, staying*/}
                            {/*                                on task,*/}
                            {/*                                on target, and on budget.*/}
                            {/*                                I also appreciate their quick and courteous responses. I*/}
                            {/*                                highly*/}
                            {/*                                recommend their service!”*/}
                            {/*                            </p>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="text-center mt-4">*/}
                            {/*                            <label className="font-lato font-size-20 font-weight-700">Arlene*/}
                            {/*                                McCoy</label>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </li>*/}
                            {/*        <li className="splide__slide">*/}
                            {/*            <div className="">*/}
                            {/*                <div className=" ">*/}
                            {/*                    <div*/}
                            {/*                        className="card br-10 box-shadow custom-card text-color-grey-dark">*/}
                            {/*                        <div className="col-2">*/}
                            {/*                            <img className="img-fluid"*/}
                            {/*                                 src="images/landing/quote-icon-inside-landing-page-card.png" alt={"quotes"}/>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="card-body text-center mt-4">*/}
                            {/*                            <p className="card-text font-lato font-size-20 font-weight-400">*/}
                            {/*                                We thank 7dots for the wonderful job in helping us*/}
                            {/*                                develop our*/}
                            {/*                                website and mobile app. Everyone was professional,*/}
                            {/*                                excellent and*/}
                            {/*                                hard working.*/}
                            {/*                                Thanks to them, we were able to achieve our goal on*/}
                            {/*                                time, and we*/}
                            {/*                                look forward to taking services from them in the*/}
                            {/*                                future. </p>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="text-center mt-4">*/}
                            {/*                            <label className="font-lato font-size-20 font-weight-700">Rober*/}
                            {/*                                Ivov</label>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </li>*/}
                            {/*        <li className="splide__slide ">*/}
                            {/*            <div className="">*/}
                            {/*                <div className="">*/}
                            {/*                    <div*/}
                            {/*                        className="card br-10 box-shadow custom-card text-color-grey-dark ">*/}
                            {/*                        <div className="col-2">*/}
                            {/*                            <img className="img-fluid"*/}
                            {/*                                 src="images/landing/quote-icon-inside-landing-page-card.png" alt={"quotes"}/>*/}
                            {/*                        </div>*/}

                            {/*                        <div className="card-body text-center mt-4">*/}
                            {/*                            <p className="card-text font-lato font-size-20 font-weight-400">*/}
                            {/*                                We would like to express our satisfaction on the*/}
                            {/*                                cooperation*/}
                            {/*                                regarding SEO-friendly content service for our*/}
                            {/*                                website.The whole*/}
                            {/*                                team did a very professional job. We are satisfied with*/}
                            {/*                                the*/}
                            {/*                                online marketing solutions given to us.*/}
                            {/*                                I highly recommend their service! They're reliable,*/}
                            {/*                                honest, and*/}
                            {/*                                an experienced digital agency with killer online*/}
                            {/*                                marketing*/}
                            {/*                                solutions for your brand.*/}
                            {/*                            </p>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="text-center mt-4">*/}
                            {/*                            <label*/}
                            {/*                                className="font-lato font-size-20 font-weight-700">Andrew</label>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </li>*/}
                            {/*        <li className="splide__slide">*/}
                            {/*            <div className="">*/}
                            {/*                <div className="card br-10 box-shadow custom-card text-color-grey-dark">*/}
                            {/*                    <div className="col-2">*/}
                            {/*                        <img className="img-fluid"*/}
                            {/*                             src="images/landing/quote-icon-inside-landing-page-card.png" alt={"quotes"}/>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="card-body text-center mt-4">*/}
                            {/*                        <p className="card-text font-lato font-size-20 font-weight-400">*/}
                            {/*                            I’m really happy that I hired an expert team of logo*/}
                            {/*                            designers to*/}
                            {/*                            design the perfect logo for my brand.*/}
                            {/*                            Their work is fresh, bold, innovative and exciting.*/}
                            {/*                            I'd totally recommend their services for any kind of logo*/}
                            {/*                            design*/}
                            {/*                            project, whether it's big or small.*/}
                            {/*                        </p>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="text-center mt-4">*/}
                            {/*                        <label className="font-lato font-size-20 font-weight-700">R.C*/}
                            {/*                            Smith</label>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </li>*/}
                            {/*        <li className="splide__slide ">*/}
                            {/*            <div className="">*/}
                            {/*                <div className="card br-10 box-shadow custom-card text-color-grey-dark ">*/}
                            {/*                    <div className="col-2">*/}
                            {/*                        <img className="img-fluid"*/}
                            {/*                             src="images/landing/quote-icon-inside-landing-page-card.png" alt={"quotes"}/>*/}
                            {/*                    </div>*/}

                            {/*                    <div className="card-body text-center mt-4">*/}
                            {/*                        <p className="card-text font-lato font-size-20 font-weight-400">*/}
                            {/*                            We had a fantastic experience working with 7dots team*/}
                            {/*                            throughout the*/}
                            {/*                            whole graphic design process.*/}
                            {/*                            The designing team shows their talent by being able to take*/}
                            {/*                            our*/}
                            {/*                            vision/mission and building it into a poster that captures*/}
                            {/*                            it*/}
                            {/*                            precisely.*/}
                            {/*                            For the quality of work provided and customer service I*/}
                            {/*                            experienced,*/}
                            {/*                            they have found a lifetime customer in me.*/}
                            {/*                        </p>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="text-center mt-4">*/}
                            {/*                        <label className="font-lato font-size-20 font-weight-700">Emily*/}
                            {/*                            k.</label>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center gap-5 ">
                            <div className="button-container  ">
                                <button type="button" onClick={goReviewPrev} className="btn-left-right-arrow"
                                        id="review-left-arrow"><img
                                    className="img-fluid"
                                    src="images/icons/arrow-left-icon.png"
                                    alt="left arrow"/>
                                </button>
                            </div>
                            <div className="button-contact">
                                <button type="button" onClick={goReviewNext} className="btn-left-right-arrow"
                                        id="review-right-arrow"><img
                                    className="img-fluid" src="images/icons/arrow-right-icon.png" alt="right arrow"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row py-md-4 py-2"></div>
                </div>
            </div>

            {/*Contact us*/}
            {/*<div className="row bg-white d-flex justify-content-center align-items-center pb-4">*/}
            {/*    <div className="col-10 pb-4">*/}
            {/*        <div className="row mt-4">*/}
            {/*            <div className="col-12">*/}
            {/*                <div className="d-flex align-items-center justify-content-center mt-5">*/}
            {/*                    <label id="contact"*/}
            {/*                           className="font-size-48 font-lato font-weight-700 text-color-black res-font-size-24">*/}
            {/*                        Contact us*/}
            {/*                    </label>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="row mt-4 pb-md-4">*/}
            {/*            <div className="col-md-6 col-12 mt-4">*/}
            {/*                <form method="POST" id="contact-form"*/}
            {/*                      action="https://script.google.com/macros/s/AKfycbwMAwOsE7DOKr2lMj3wYh6HRXzQtrMP_FMv8TzlUd1i4K9G0RV8JvbS1iSCB3t-axWB/exec">*/}
            {/*                    <div>*/}
            {/*                        <label htmlFor="exampleFormControlNameInput"*/}
            {/*                               className="form-label font-lato text-color-black font-size-16 font-weight-400">Name </label>*/}
            {/*                        <input type='text' name="Name" required="required"*/}
            {/*                               className="form-control py-2 br-8"*/}
            {/*                               id="exampleFormControlNameInput"*/}
            {/*                               placeholder="Enter your name"/>*/}
            {/*                    </div>*/}
            {/*                    <div className="mt-4">*/}
            {/*                        <label htmlFor="exampleFormControlPhoneNumberInput"*/}
            {/*                               className="form-label font-lato text-color-black font-size-16 font-weight-400">Phone*/}
            {/*                            number</label>*/}
            {/*                        <input type="tel" name="PhoneNumber" className="form-control py-2 br-8 "*/}
            {/*                               id="exampleFormControlPhoneNumberInput"*/}
            {/*                               placeholder="Enter your phone number"/>*/}
            {/*                    </div>*/}
            {/*                    <div className="mt-4">*/}
            {/*                        <label htmlFor="exampleFormControlEmailInput"*/}
            {/*                               className="form-label font-lato text-color-black font-size-16 font-weight-400">Email</label>*/}
            {/*                        <input type="email" name="Email" className="form-control py-2 br-8"*/}
            {/*                               id="exampleFormControlEmailInput"*/}
            {/*                               placeholder="Enter your email address"/>*/}
            {/*                    </div>*/}
            {/*                    <div className="mt-4">*/}
            {/*                        <label htmlFor="exampleFormControlTextarea"*/}
            {/*                               className="form-label font-lato text-color-black font-size-16 font-weight-400">Message</label>*/}
            {/*                        <textarea className="form-control br-8" name="Message"*/}
            {/*                                  id="exampleFormControlTextarea"*/}
            {/*                                  placeholder="Type your message..." rows="5"></textarea>*/}
            {/*                        <div className="mt-4">*/}
            {/*                            <div className="d-inline-flex gap-md-5">*/}
            {/*                                <input type="text" id="captchaInput" className="form-control py-2 br-8"*/}
            {/*                                       placeholder="Enter Captcha"/>*/}
            {/*                                <div className="border-0 p-4 shadow ">*/}
            {/*                                    <label id="captchaQuestion"*/}
            {/*                                           className="font-lato text-color-black user-select-none font-size-28 font-weight-700"></label>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div id="captchaError" className="text-danger mt-2"></div>*/}
            {/*                        </div>*/}
            {/*                        <button type="submit" id="btn-form-submit"*/}
            {/*                                className="col-md-4 col-12 button-submit bg-indigo font-lato text-white border-none font-size-24 font-weight-400 mt-4">*/}
            {/*                            Submit*/}
            {/*                            <div id="loading-spinner" className="spinner"></div>*/}
            {/*                        </button>*/}
            {/*                        <div className="mt-4 p-3" id="msg-container">*/}
            {/*                            <label id="display-msg" className="text-white"></label>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </form>*/}
            {/*            </div>*/}
            {/*            <div className="col-12 col-md-6 p-0 ">*/}
            {/*                <div className="row mt-md-4">*/}
            {/*                    <div className="col-6 ">*/}
            {/*                        <div className="d-flex flex-column mx-2 gap-2 res-display-none">*/}
            {/*                            <div className="row">*/}
            {/*                                <div className="col-2 mb-2">*/}
            {/*                                    <img className="img-fluid" src="images/landing/icon-mail.png"*/}
            {/*                                         alt="mail icon"/>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div className="d-flex flex-column justify-content-center align-items-start">*/}
            {/*                                <label*/}
            {/*                                    className="font-size-20 text-color-black font-weight-700">Email</label>*/}
            {/*                                <label*/}
            {/*                                    className="font-size-20 font-weight-400 text-color-light-grey">business@7dotss.com</label>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}

            {/*                    </div>*/}
            {/*                    <div className="col-6">*/}
            {/*                        <div className="d-flex flex-column mx-2 gap-2 res-display-none ">*/}
            {/*                            <div className="row">*/}
            {/*                                <div className="col-2 mb-2">*/}
            {/*                                    <img className="img-fluid" src="images/landing/icon-phone.png"*/}
            {/*                                         alt="phone logo"/>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div className="d-flex flex-column justify-content-center align-items-start">*/}
            {/*                                <label*/}
            {/*                                    className="font-size-20 font-weight-700 text-color-black">Phone</label>*/}
            {/*                                <label*/}
            {/*                                    className="font-size-20 font-weight-400 text-color-light-grey">+918058406194*/}
            {/*                                </label>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="row pb-4">*/}
            {/*                        <div className="col-12 mt-4 mt-md-4">*/}
            {/*                            <iframe*/}
            {/*                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.3757733731513!2d78.35428197389304!3d17.441719583454766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb938babd6f833%3A0xec8e6a3a616b5334!2s7%20Dots%20Smart%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1703572689456!5m2!1sen!2sin"*/}
            {/*                                className="mt-3 br-10" style={{border:"0"}} width="100%" height="180%"*/}
            {/*                                allowFullScreen=""*/}
            {/*                                title={"map"}*/}
            {/*                                loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="col-12 pb-4 mb-4 d-block d-md-none"></div>*/}
            {/*    <div className="col-12 pb-4 mb-4 d-block d-md-none"></div>*/}
            {/*</div>*/}
            <ContactUs/>

            <div className="row bg-interface-color font-lato">
                <div className="col-12 ">
                    <div className="row d-flex justify-content-center border-bottom border-grey-3">
                    </div>
                </div>
            </div>

            {/*footer section*/}
            <FooterComponent/>
        </div>
    )
}

export default LandingPage;
