import FooterComponent from "../FooterComponent/footerComponent";
import {Link} from "react-router-dom";
import NavbarComponent from "../NavbarComponent/navbarComponent";
import {useEffect} from "react";
import ContactUs from "../ContactUs/contactUs";

const About = () => {

    useEffect(() => {
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    return (
        <div className="container-fluid">
            <div id="about-us" className="row bg-interface-color justify-content-center font-lato">
                <div className="col-12">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12">
                            <NavbarComponent/>
                        </div>
                    </div>

                    {/*hero section*/}
                    <div className="row py-4 mt-4 bg-about-us-container">
                        <div className="col-12 text-align-center text-white my-4">
                            {/*                <img class="img-fluid" src={"images/About/about-us-mission-image.png"} alt="mission">*/}
                            <div className="row py-4 justify-content-center">
                                <div className="col-12 col-md-8 justify-content-center">
                                    <div className="d-flex flex-column justify-content-center align-items-center p-4">
                                        <label className=" font-georgia font-size-56 font-georgia">
                                            We don’t just deliver DIGITAL PRODUCTS, We deliver the FUTURE
                                        </label>
                                        <label className="font-size-20">
                                            Top-notch solutions that cater to all your digital needs
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*our services*/}
                    <div className="row d-flex justify-content-center mt-4">
                        <div className="col-12 col-md-10 pt-md-2">
                            <div className="row mt-md-4 pt-md-4">
                                <div className="col-12 ">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <label
                                            className="font-size-48 font-weight-700 res-text-align-center res-font-size-24">
                                            Our Services
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4 pt-md-4 mb-4 row-gap-4 justify-content-between pb-4 px-2">
                                <div className="col-md-6 col-12 d-flex flex-grow-1 ">
                                    <div className="card br-10 p-md-4 d-flex flex-column gap-2">
                                        <div
                                            className="d-flex card-body flex-column align-items-center justify-content-between res-text-align-center gap-3">
                                            <div className="col-1">
                                                <img className="img-fluid"
                                                     src={"images/About/about-us-icon-website and app-design.png"}
                                                     alt="website app"/>
                                            </div>
                                            <h5 className="card-title font-weight-700 font-size-24">Website & App
                                                Design</h5>
                                            <p className="font-size-16 mb-0 font-weight-400 text-color-grey-dark text-align-center">
                                                Our expert design team combines analytics with creativity to craft
                                                digital products that delight users. Through usability audits, user
                                                interviews, and live observations, we design end-to-end experiences and
                                                intuitive interfaces that help you scale and stand the test of time. Our
                                                unique approach and competitive pricing have established us as some of
                                                the top designers globally.
                                            </p>
                                            <Link to={"/website-app-design"}
                                                  className="text-primary text-decoration-none">
                                                Know more
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 d-flex flex-grow-1 ">
                                    <div className="card br-10 d-flex flex-column gap-2 p-md-4">
                                        <div
                                            className="d-flex card-body flex-column align-items-center justify-content-between gap-3 res-text-align-center">
                                            <div className="col-1">
                                                <img className="img-fluid"
                                                     src={"images/About/about-us-icon-software-developement.png"}
                                                     alt="software development"/>
                                            </div>
                                            <h5 className="card-title font-weight-700 font-size-24">Software
                                                Development</h5>
                                            <div className="text-center">
                                                <p className="font-size-16 font-weight-400 text-color-grey-dark">
                                                    Our expertise spans Python Development, React Development, Angular
                                                    Development, Flutter App Development, Web and Mobile App
                                                    Development, AWS integration, and comprehensive software solutions.
                                                    7dots is a leading name in customised software development globally,
                                                    delivering cost-effective, functional, and versatile solutions
                                                    tailored to your needs.
                                                </p>
                                            </div>
                                            <Link to={"/website-app-development"}
                                                  className="text-primary text-decoration-none">
                                                Know more
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-12 d-flex flex-grow-1">
                                    <div className="card br-10 p-md-4 d-flex flex-column gap-2">
                                        <div
                                            className="d-flex card-body  flex-column align-items-center justify-content-between gap-3 res-text-align-center">
                                            <div className="col-1">
                                                <img className="img-fluid"
                                                     src={"images/About/about-us-content-creation.png"}
                                                     alt="content creation"/>
                                            </div>
                                            <h5 className="card-title font-weight-700 font-size-24">Content
                                                Writing</h5>
                                            <p className="font-size-16 font-weight-400 text-color-grey-dark text-align-center">
                                                Empower your brand to reach its full potential with our high-quality
                                                content. Our expert content writers ensure your web content, app
                                                content, Play Store listings, blogs, and articles are SEO-enriched. With
                                                strategic social media marketing, you'll see increased engagement with
                                                likes, comments, followers, and traffic to your platforms. Your digital
                                                growth is our mission!
                                            </p>
                                            <Link to={"/content-writing"}
                                                  className="text-primary text-decoration-none">
                                                Know more
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-12 d-flex flex-grow-1">
                                    <div className="card br-10 p-md-4 d-flex flex-column gap-2">
                                        <div
                                            className="d-flex card-body flex-column align-items-center justify-content-between gap-3 res-text-align-center">
                                            <div className="col-1">
                                                <img className="img-fluid"
                                                     src={"images/About/about-us-icon-software-developement.png"}
                                                     alt="graphic design"/>
                                            </div>
                                            <h5 className="card-title font-weight-700 font-size-24">Graphic
                                                Design</h5>
                                            <p className="font-size-16 mb-0 font-weight-400 text-color-grey-dark text-align-center">
                                                We provide top-notch logo and poster designs that ensure your brand and
                                                the visuals not only coexist but thrive together in harmony. We don't
                                                just create designs for today; we build for tomorrow. If you're
                                                searching for the best graphic designers or affordable logo and poster
                                                design services, we're here to help!
                                            </p>
                                            <div>
                                                <Link to={"/graphic-design"}
                                                      className="text-primary text-decoration-none">
                                                    Know more
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-4 py-4 d-none d-md-block"></div>
                            <div className="row mt-4 justify-content-center">
                                <div className="col-12 mb-4 pb-4">
                                    <div className="row">
                                        <div className=" col-12 mt-4">
                                            <div className="d-flex align-items-center justify-content-center ">
                                                <label
                                                    className="font-size-48 font-weight-700 res-text-align-center res-font-size-24">
                                                    Our Process
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4 d-md-flex justify-content-between font-lato">
                                            <div
                                                className="row d-flex gap-4 gap-md-0 flex-md-row flex-column justify-content-start py-4 px-4 px-md-0">
                                                <div
                                                    className="col-12 col-md-2 d-flex flex-column align-items-center justify-content-start">
                                                    <div
                                                        className="row d-flex justify-content-center align-items-center">
                                                        <div className="col-4 col-md-12 ">
                                                            <img className="img-fluid"
                                                                 src={"images/works/our-process-discussion.png"}
                                                                 alt="discussion icon"/>
                                                        </div>
                                                    </div>
                                                    <label
                                                        className="font-size-20 font-weight-700 text-color-black">Discussion</label>
                                                    <label
                                                        className="font-size-14 font-weight-400 text-color-grey-1 text-align-center">
                                                        Clear communication to understand your digital needs
                                                    </label>
                                                </div>
                                                <div
                                                    className="col-12 col-md-2 d-flex flex-column align-items-center justify-content-start">
                                                    <div
                                                        className="row d-flex justify-content-center align-items-center">
                                                        <div className="col-4 col-md-12 ">
                                                            <img className="img-fluid"
                                                                 src={"images/works/our-process-planning.png"}
                                                                 alt="process icon"/>
                                                        </div>
                                                    </div>
                                                    <label
                                                        className="font-size-20 font-weight-700 text-color-black">Planning</label>
                                                    <label
                                                        className="font-size-14 font-weight-400 text-color-grey-1 text-align-center">
                                                        Building proper strategy to achieve the digital goal.
                                                    </label>
                                                </div>
                                                <div
                                                    className="col-12 col-md-2 d-flex flex-column align-items-center justify-content-start">
                                                    <div
                                                        className="row d-flex justify-content-center align-items-center">
                                                        <div className="col-4 col-md-12">
                                                            <img className="img-fluid"
                                                                 src={"images/works/our-process-execution.png"}
                                                                 alt="execution icon"/>
                                                        </div>
                                                    </div>
                                                    <label
                                                        className="font-size-20 font-weight-700 text-color-black">Execution</label>
                                                    <label
                                                        className="font-size-14 font-weight-400 text-color-grey-1 text-align-center">
                                                        Blending creativity and cutting-edge technology as per strategy.
                                                    </label>
                                                </div>
                                                <div
                                                    className="col-12 col-md-2 d-flex flex-column align-items-center justify-content-start">
                                                    <div
                                                        className="row d-flex justify-content-center align-items-center">
                                                        <div className="col-4 col-md-12">
                                                            <img className="img-fluid"
                                                                 src={"images/works/our-process-monitoring.png"}
                                                                 alt="monitoring icon"/>
                                                        </div>
                                                    </div>
                                                    <label
                                                        className="font-size-20 font-weight-700 text-color-black">Monitoring</label>
                                                    <label
                                                        className="font-size-14 font-weight-400 text-color-grey-1 text-align-center">
                                                        Monitor to make sure of the delightful future user experiences.
                                                    </label>
                                                </div>
                                                <div
                                                    className="col-12 col-md-2 d-flex flex-column align-items-center justify-content-start">
                                                    <div
                                                        className="row d-flex justify-content-center align-items-center">
                                                        <div className="col-4 col-md-12 ">
                                                            <img className="img-fluid"
                                                                 src={"images/works/our-process-delivery.png"}
                                                                 alt="delivery icon"/>
                                                        </div>
                                                    </div>
                                                    <label
                                                        className="font-size-20 font-weight-700 text-color-black">Delivery</label>
                                                    <label
                                                        className="font-size-14 font-weight-400 text-color-grey-1 text-align-center">
                                                        Believe in staying on top of the delivery date.
                                                    </label>
                                                </div>
                                                <div
                                                    className="col-12 col-md-2 d-flex flex-column align-items-center justify-content-start">
                                                    <div
                                                        className="row d-flex justify-content-center align-items-center">
                                                        <div className="col-4 col-md-12 ">
                                                            <img className="img-fluid"
                                                                 src={"images/About/our-process-delivery-image.png"}
                                                                 alt="post delivery icon"/>
                                                        </div>
                                                    </div>
                                                    <label
                                                        className="font-size-20 font-weight-700 text-color-black text-center">Post
                                                        delivery support</label>
                                                    <label
                                                        className="font-size-14 font-weight-400 text-color-grey-1 text-align-center">
                                                        Offering support after delivery in case any issues arise.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4 mt-md-4 px-2">
                                        <div className="col-12 text-center pt-md-4 my-4">
                                            <h2 className="font-size-48 font-weight-700">Our Team</h2>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3 mb-4">
                                            <div className="d-flex flex-column align-items-left">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img className="img-fluid w-100 br-10"
                                                             src={"images/About/rakesh.png"}
                                                             alt="Rakesh Gudipudi"/>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <h3 className="font-size-20 font-weight-400 text-color-light-grey">Rakesh
                                                        Gudipudi
                                                        <a className="ms-3" rel="noreferrer"
                                                           href="https://www.linkedin.com/in/rakeshgudipudi/?originalSubdomain=in"
                                                           target="_blank">
                                                            <img className="w-24 h-24 cursor-pointer"
                                                                 src={"images/works/our-works-linkedin.png"}
                                                                 alt="LinkedIn"/>
                                                        </a></h3>
                                                    <p className="font-size-20 font-weight-500 mb-2">Founder</p>
                                                    <p className="font-size-14 font-weight-400 text-color-light-grey">
                                                        Blend method and madness to create innovative digital products.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-3 mb-4">
                                            <div className="d-flex flex-column align-items-left">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img className="img-fluid w-100 br-10"
                                                             src={"images/About/hanu.png"}
                                                             alt="Hanu Kiran"/>
                                                    </div>
                                                </div>
                                                <div className="mt-3 text-left">
                                                    <h3 className="font-size-20 font-weight-400 text-color-light-grey">Hanu
                                                        Kiran
                                                        <a className="ms-3" rel="noreferrer"
                                                           href="https://www.linkedin.com/in/hanu-kiran-0472a3158?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                                                           target="_blank">
                                                            <img className="w-24 h-24 cursor-pointer"
                                                                 src={"images/works/our-works-linkedin.png"}
                                                                 alt="LinkedIn"/>
                                                        </a></h3>
                                                    <p className="font-size-20 font-weight-500 mb-2">Business
                                                        Developer</p>
                                                    <p className="font-size-14 font-weight-400 text-color-light-grey">
                                                        Ensure smooth client communication and deliver high-quality
                                                        projects.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-3 mb-4">
                                            <div className="d-flex flex-column align-items-left">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img className="img-fluid w-100 br-10"
                                                             src={"images/About/chandini.png"}
                                                             alt="Chandini Sabbithi"/>
                                                    </div>
                                                </div>
                                                <div className="mt-3 text-left">
                                                    <h3 className="font-size-20 font-weight-400 text-color-light-grey">Chandini
                                                        Sabbithi
                                                        <a className="ms-3" rel="noreferrer"
                                                           href="https://www.linkedin.com/in/chandini-sabbithi-14225a227/?originalSubdomain=in"
                                                           target="_blank">
                                                            <img className="w-24 h-24 cursor-pointer "
                                                                 src={"images/works/our-works-linkedin.png"}
                                                                 alt="LinkedIn"/>
                                                        </a></h3>
                                                    <p className="font-size-20 font-weight-500 mb-2">HR & Operation
                                                        Head</p>
                                                    <p className="font-size-14 font-weight-400 text-color-light-grey">
                                                        Fosters growth and success for all in a supportive environment.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-3 mb-4">
                                            <div className="d-flex flex-column align-items-left">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img className={"img-fluid w-100 br-10"}
                                                             src={"images/About/lipsa.png"}
                                                             alt={"Lipsa Behera"}/>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <h3 className="font-size-20 font-weight-400 text-color-light-grey">Lipsa
                                                        Behera
                                                        <a className="ms-3" rel="noreferrer"
                                                           href="https://www.linkedin.com/in/lipsa-behera-227a01213/?originalSubdomain=in"
                                                           target="_blank">
                                                            <img className="w-24 h-24 cursor-pointer"
                                                                 src={"images/works/our-works-linkedin.png"}
                                                                 alt="LinkedIn"/>
                                                        </a></h3>
                                                    <p className="font-size-20 font-weight-500 mb-2">Design Head</p>
                                                    <p className="font-size-14 font-weight-400 text-color-light-grey">
                                                        Create delightful user experience by combining analytics and
                                                        creativity.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*contact us*/}
                    <ContactUs/>

                    <div className="row bg-interface-color font-lato">
                        <div className="col-12">
                            <div className="row d-flex justify-content-center border-bottom border-grey-3">
                            </div>
                        </div>
                    </div>
                    {/*footer section*/}
                    <FooterComponent/>
                </div>
            </div>
        </div>
    )
}

export default About;
