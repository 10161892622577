// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDhFq27UmFykONOe2GrbJMCrtEtnEyn65c",
    authDomain: "dotss-3bad5.firebaseapp.com",
    projectId: "dotss-3bad5",
    storageBucket: "dotss-3bad5.appspot.com",
    messagingSenderId: "1054996061125",
    appId: "1:1054996061125:web:d2c0c9e1f6b23b53c489ac",
    measurementId: "G-H2NGZ0DHT6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export {analytics}
