import {Container, Nav, Navbar} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {logEvent} from "firebase/analytics";
import {analytics} from "../FirebaseAuthentication/firebase";

const NavbarComponent = () => {

    const [path, setPath] = useState('');
    const worksPart = ['/works', '/website-app-development', '/website-app-design', '/graphic-design', '/content-writing'];
    const pathName = useLocation();

    const handleContactLogButton = () => {
        logEvent(analytics, 'contact_button', {
            page_title: 'Contact Button',
            page_location: window.location.href,
            page_path: pathName.pathname,
        });
    }

    useEffect(() => {
        setPath(pathName.pathname);
    }, [pathName.pathname]);

    return (
        <div className={"row justify-content-center mt-4"}>
            <Navbar expand="md" className="bg-white navbar-container ps-2 col-10 br-50">
                <Container className={"row"}>
                    <Navbar.Brand href="#home" className={"col-md-6 col-9"}>
                        <div className={"row"}>
                            <div className="col-6 col-md-3">
                                <div className=" d-flex justify-content-md-start justify-content-center">
                                    <Link to={"/"}>
                                        <img className="img-fluid"
                                             src="/images/landing/7_dots_logo.png"
                                             alt="7 dots logo"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className={"col-2 border-0"}/>
                    <Navbar.Collapse id="basic-navbar-nav" className={"d-md-flex justify-content-md-end col-md-6"}>
                        <Nav className="me-left align-items-md-center gap-md-3 gap-3 py-4 py-md-0">
                            <Nav>
                                <Link to={"/works"}>
                                    <button type="button"
                                            className={`btn btn-light bg-white ${worksPart.includes(path) ? 'text-indgo text-decoration-underline' : ''} border-0 font-size-20 font-weight-400`}>
                                        Works
                                    </button>
                                </Link>
                            </Nav>
                            <Nav className="font-size-20 font-weight-400">
                                <Link to={"/about-us"}>
                                    <button type="button"
                                            className={`btn btn-light bg-white ${path === '/about-us' ? 'text-indgo text-decoration-underline' : ''} border-0 font-size-20 font-weight-400`}>
                                        About us
                                    </button>
                                </Link>
                            </Nav>
                            <Nav className="font-size-20 font-weight-400">
                                <Link to={"/blogs"}>
                                    <button type="button"
                                            className={`btn btn-light bg-white ${path === '/blogs' ? 'text-indgo text-decoration-underline' : ''} border-0 font-size-20 font-weight-400`}>
                                        Blog
                                    </button>
                                </Link>
                            </Nav>
                            <Nav className={"align-self-center"}>
                                <a href={"#contact"}>
                                    <button onClick={handleContactLogButton}
                                            className="bg-indigo text-white border-0 font-size-20 font-weight-400 button-contact-us">
                                        Contact us
                                    </button>
                                </a>
                            </Nav>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default NavbarComponent
