import NavbarComponent from "../NavbarComponent/navbarComponent";
import FooterComponent from "../FooterComponent/footerComponent";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {createClient} from "contentful";
import * as Icon from 'react-feather';

const BlogsPage = () => {

    const [blogsDataItems, setBlogsDataItems] = useState([]);
    const [blogsData, setBlogsData] = useState();
    const [activeTag, setActiveTag] = useState('');
    const [allTags, setAllTags] = useState([]);
    const [searchBlogData, setSearchBlogData] = useState([]);
    const [blogSearch, setBlogSearch] = useState('');

    const client = createClient({
        space: 'pr1iuhatqedc',
        accessToken: 'wMmCg3eBGgZLKHpTMD82274BkSm_hkcKO5Dl1it0mDc',
    });

    const getImageUrlByBlogImageId = (blogImageId, assets) => {
        const matchingAsset = assets.find(asset => asset.sys.id === blogImageId);
        return matchingAsset.fields.file.url;
    };

    const getAllBlogsByTags = async (tag) => {
        setActiveTag(tag);
        const tagsData = await client.getEntries({'metadata.tags.sys.id[in]': tag});
        // console.log(tagsData);
        setBlogsDataItems(tagsData.items);
    }

    const getBlogsBySearch = async (e) => {
        setBlogSearch(e.target.value);
        const searchData = await client.getEntries({
            content_type: 'blogs',
            'fields.blogHeading[match]': e.target.value.toLowerCase()
        });
        // console.log("search data: ", searchData);
        setSearchBlogData(searchData.items);
    }

    const getPublishedDate = (pubDate) => {
        const date = new Date(pubDate);
        const day = date.getDate();
        const month = date.toLocaleString('en-US', {month: 'short'});
        const year = date.getFullYear();

        return `${month} ${day} ${year}`
    }


    const fetchGetAllBlogsData = async () => {
        setActiveTag('All blogs')
        try {
            const clientData = await client.getEntries();
            setBlogsData(clientData);
            setBlogsDataItems(clientData.items);
            // console.log(clientData);
            const tags = await client.getTags();
            // console.log(tags.items);
            setAllTags(tags.items);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchGetAllBlogsData();
        document.querySelector("body").scrollTo(0,0);
    }, []);

    return (
        <div className="container-fluid">
            <div id="works" className="row bg-interface-color justify-content-center align-items-center font-lato">
                <div className="col-12">
                    {/*navbar*/}
                    <div className="row d-flex justify-content-center">
                        <div className="col-12">
                            <NavbarComponent/>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="col-10">
                            <div className="row mt-4">
                                <div className="col-12 pt-2 pt-md-4 mt-md-4">
                                    <div
                                        className="d-flex flex-column align-items-center text-center justify-content-center gap-2">
                                        <label className="font-size-56 font-weight-700 font-georgia">
                                            Blogs
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10 mt-md-4 my-2">
                                    <label
                                        className="d-flex align-items-center justify-content-center text-align-center font-size-24 font-weight-400 text-color-464646 res-flex-column res-text-align-center">
                                        Read our blog for insider tips: UI/UX Design, Software Development, Business, and more!
                                    </label>
                                </div>
                            </div>
                            <div className={"row d-flex justify-content-between my-5 py-2"}>
                                <div className={"col-12 col-md-7 p-0"}>
                                    <div className={"d-flex align-items-center gap-3 flex-wrap"}>
                                        <div>
                                            <button type={"button"} onClick={fetchGetAllBlogsData}
                                                    className={`${activeTag === 'All blogs' ? 'bg-indigo text-white border-0' : 'border-grey-3 text-color-black bg-transparent'} px-3 py-2 font-lato font-size-16 font-weight-400 br-78`}>All
                                                Blogs
                                            </button>
                                        </div>
                                        {allTags && (allTags.map((eachTag, index) => (
                                            <button type={"button"} onClick={() => getAllBlogsByTags(eachTag.sys.id)}
                                                    key={index}
                                                    className={`${activeTag === `${eachTag.sys.id}` ? 'bg-indigo text-white border-0' : 'border-grey-3 text-color-black bg-transparent'} px-3 py-2 font-lato font-size-16 font-weight-400 br-78`}>
                                                {eachTag.name}
                                            </button>
                                        )))}
                                    </div>
                                </div>
                                <div className={"col-12 col-md-3 p-0"}>
                                    <div
                                        className={"d-flex form-control w-100 bg-transparent align-items-center gap-2"}>
                                        <Icon.Search color={"#8D8D8D"} size={16}/>
                                        <div>
                                            <input type={"search"} placeholder={"Search"} onChange={getBlogsBySearch}
                                                   className={"border-0 tag-search-bar bg-transparent"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"row d-flex flex-wrap row-gap-5 mb-5 pb-2"}>
                                {blogSearch ? (
                                    searchBlogData.length ? (searchBlogData.map((searchBlogs, index) => (
                                        <div className={"col-12 col-md-4 d-flex flex-column align-items-stretch"} key={index}>
                                            <Link to={`/blogs/${searchBlogs.fields.blogSlug}`}>
                                                <div className={"w-100"}>
                                                    <img
                                                        src={`${getImageUrlByBlogImageId(searchBlogs.fields.blogImage.sys.id, blogsData.includes.Asset)}`}
                                                        alt={"blog"} className={"img-fluid w-100 br-10"}/>
                                                </div>
                                                <div className={"mt-4"}>
                                                    <label
                                                        className={"font-size-14 font-weight-700 font-lato text-color-black bg-blog-tag-name px-2 py-1 br-4"}>
                                                        {searchBlogs.metadata.tags ? searchBlogs.metadata.tags[0].sys.id.charAt(0).toUpperCase() + searchBlogs.metadata.tags[0].sys.id.slice(1).toLowerCase() : ''}
                                                    </label>
                                                </div>
                                                <div className={"mt-3"}>
                                                    <label
                                                        className={"font-size-24 font-weight-700 font-lato text-color-black"}>
                                                        {searchBlogs.fields.blogHeading}
                                                    </label>
                                                </div>
                                                <div className={"mt-2"}>
                                                    <label
                                                        className={"font-size-16 font-weight-400 font-lato text-color-grey-dark"}>
                                                        {searchBlogs.fields.blogPublishedDate ? getPublishedDate(searchBlogs.fields.blogPublishedDate) : ''}
                                                    </label>
                                                </div>
                                            </Link>
                                        </div>
                                    ))) : ('')
                                ): (blogsDataItems.map((blogs, index) => (
                                    <div className={"col-12 col-md-4 d-flex flex-column align-items-stretch"} key={index}>
                                        <Link to={`/blogs/${blogs.fields.blogSlug}`}>
                                            <div className={""}>
                                                <img
                                                    src={`${blogs.fields.blogImage ? `${getImageUrlByBlogImageId(blogs.fields.blogImage.sys.id, blogsData.includes.Asset)}` : '/images/blogs/default-blog-image.png'}`}
                                                    alt={"blog"} className={"img-fluid w-100 object-fit-cover br-10"}/>
                                            </div>
                                            <div className={"mt-4"}>
                                                <label
                                                    className={"font-size-14 font-weight-700 font-lato text-color-black bg-blog-tag-name px-2 py-1 br-4"}>
                                                    {blogs.metadata.tags ? blogs.metadata.tags[0].sys.id.charAt(0).toUpperCase() + blogs.metadata.tags[0].sys.id.slice(1).toLowerCase() : ''}
                                                </label>
                                            </div>
                                            <div className={"mt-3"}>
                                                <label
                                                    className={"font-size-24 font-weight-700 font-lato text-color-black"}>
                                                    {blogs.fields.blogHeading}
                                                </label>
                                            </div>
                                            <div className={"mt-2"}>
                                                <label
                                                    className={"font-size-16 font-weight-400 font-lato text-color-grey-dark"}>
                                                    {blogs.fields.blogPublishedDate ? getPublishedDate(blogs.fields.blogPublishedDate) : ''}
                                                </label>
                                            </div>
                                        </Link>
                                    </div>
                                )))}
                            </div>
                        </div>
                    </div>

                    {/*border*/}
                    <div className="row bg-interface-color font-lato">
                        <div className="col-12 ">
                            <div className="row d-flex justify-content-center mt-4 border-bottom border-grey-3">
                            </div>
                        </div>
                    </div>
                </div>

                {/*footer section*/}
                <FooterComponent/>
            </div>
        </div>
    )
}

export default BlogsPage;
