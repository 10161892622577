import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope, faPhone, faLocationDot} from "@fortawesome/free-solid-svg-icons";

const FooterComponent = () => {
    return(
        <div className="row bg-interface-color font-lato">
            <div className="col-12">
                <div className="row d-flex justify-content-center mt-md-4 pb-4">
                    <div className="col-md-10 col-11 mt-md-4 mt-2">
                        <div className="row">
                            <div className="col-12">
                                <div className="row mt-4 res-flex-column">
                                    <div
                                        className="col-4 res-width-100p pt-4 d-flex flex-column justify-content-between gap-4">
                                        <div className="row">
                                            <div className="col-4 mb-3 mb-md-4 p-0">
                                                <img className="img-fluid" src={"/images/landing/7_dots_logo.png"}
                                                     alt="7 dots logo"/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 mt-md-4 p-0 d-flex justify-content-start gap-4">
                                                <div className="">
                                                    <a href="https://www.instagram.com/7dotssmartsolutions_official/" rel={"noreferrer"}
                                                       target="_blank">
                                                        <FontAwesomeIcon icon={faInstagram} size={'xl'} color={"black"} strokeWidth={2}/>
                                                        {/*<img className="img-fluid" src={"/images/landing/icon-ig.png"}*/}
                                                        {/*     alt="Instagram"/>*/}
                                                    </a>
                                                </div>
                                                <div className="">
                                                    <a href="https://www.linkedin.com/company/7-dots-smart-solutions/" rel={"noreferrer"}
                                                       target="_blank">
                                                        <FontAwesomeIcon icon={faLinkedin} size={'xl'} color={"black"}/>
                                                        {/*<img className="img-fluid"*/}
                                                        {/*     src={"/images/landing/icon-linkedin.png"}*/}
                                                        {/*     alt="LinkedIn"/>*/}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row res-display-none">
                                            <div className="col-12 mt-4 p-0">
                                                <label
                                                    className="font-size-16 font-weight-400 text-color-grey-dark mb-5">
                                                    Copyright © 2023 7 Dots Smart Solutions
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4 d-none d-md-block">

                                    </div>
                                    <div className="col-4 res-width-100p d-flex flex-column mt-4 gap-4 p-0">
                                        <div className="d-flex flex-column align-items-start font-lato mb-2 gap-3">
                                            <div className="row">
                                                <div className="col-12 d-flex gap-3">
                                                    <div className="">
                                                        {/*<Icon.Mail/>*/}
                                                        <FontAwesomeIcon icon={faEnvelope} size={'lg'}/>
                                                    </div>
                                                    <a href="mailto:business@7dotss.com">
                                                        <label
                                                            className="font-size-16 font-weight-400 text-color-light-grey">business@7dotss.com</label>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 d-flex gap-3">
                                                    <div className="">
                                                        {/*<Icon.Phone fill={"text-color-black"}/>*/}
                                                        <FontAwesomeIcon icon={faPhone} size={'lg'}/>
                                                    </div>
                                                    <label
                                                        className="font-size-16 font-weight-400 text-color-light-grey">+918500240435</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 d-flex gap-3">
                                                    <div className="">
                                                        {/*<Icon.MapPin fill={"text-color-black"} />*/}
                                                        <FontAwesomeIcon icon={faLocationDot} size={'lg'}/>
                                                    </div>
                                                    <label
                                                        className="font-size-16 font-weight-400 text-color-light-grey">
                                                         2-37/53, 3rd floor, near Vijetha Super Market, Vinayak Nagar,
                                                        Indira Nagar, Gachibowli, Hyderabad, Telangana 500032
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <label
                                            className="font-size-16 font-weight-400 text-color-grey-dark display-none res-display-block">
                                            Copyright © 2023 7 Dots Smart Solutions
                                        </label>
                                        <div className="d-flex gap-3">
                                            <Link to={"/terms-conditions"} style={{textDecoration: 'none'}}
                                               className="font-size-16 font-weight-400 text-color-grey-dark">Terms &
                                                conditions</Link>
                                            <Link to={"/privacy-policy"} style={{textDecoration: 'none'}}
                                               className="font-size-16 font-weight-400 text-color-grey-dark">Privacy
                                                policy</Link>
                                            <a href={"#contact"} style={{textDecoration: 'none'}}
                                               className="font-size-16 font-weight-400 text-color-grey-dark">Help</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterComponent;
