import './App.css';
import './components/Styels/scss/main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LandingPage from "./components/LandingPage/landingPage";
import Works from "./components/Works/works";
import WebsiteAppDevelopment from "./components/WebsiteAppDevelopment/websiteAppDevelopment";
import WebsiteAppDesign from "./components/WebsiteAppDesign/websiteAppDesign";
import TermsAndConditions from "./components/TermsAndConditions/termsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy/privacyPolicy";
import ContentWriting from "./components/ContentWriting/contentWriting";
import GraphicDesign from "./components/GraphicDesign/graphicDesign";
import About from "./components/About/about";
import BlogsPage from "./components/BlogsPage/blogsPage";
import EachBlogPage from "./components/EachBlogPage/eachBlogPage";

function App() {

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path={'/'} element={<LandingPage/>}/>
                    <Route path={'/works'} element={<Works/>}/>
                    <Route path={'/about-us'} element={<About/>}/>
                    <Route path={'/website-app-development'} element={<WebsiteAppDevelopment/>}/>
                    <Route path={'/website-app-design'} element={<WebsiteAppDesign/>}/>
                    <Route path={'/content-writing'} element={<ContentWriting/>}/>
                    <Route path={'/graphic-design'} element={<GraphicDesign/>}/>
                    <Route path={'/terms-conditions'} element={<TermsAndConditions/>}/>
                    <Route path={'/privacy-policy'} element={<PrivacyPolicy/>}/>
                    <Route path={'/blogs'} element={<BlogsPage/>}/>
                    <Route path={'/blogs/:id'} element={<EachBlogPage/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
